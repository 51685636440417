import React, { useState, useEffect } from "react";
import axios from "axios";
import qs from "qs";

import "../../../utilities.css"
import "./Welfare.css";

import TopBanner from "../../modules/TopBanner.js";
import ContentBlock from "../../modules/ContentBlock.js";
import Image from "../../modules/Image.js";
import ButtonFlex from "../../modules/ButtonFlex.js";

import TopBannerImage from "../../../img/topbanner/banner_coffee.jpg";
import EuroVisionImage from "../../../img/topbanner/temp_eurovision.png";
import YogaImage from "../../../img/genericYoga.jpg";
import WelfareWeekImage from "../../../img/topbanner/banner_coffee.jpg"
import default_img_src from "../../../img/logo/univ_192x192.png";


const Welfare = () => {
  const [committeePic, setCommitteePic] = useState({});
  const [documents, setDocuments] = useState({});

  const getWCRCommitteePic = async () => {
    const query = qs.stringify(
      {
        populate: { committeePicture: true }
      }
    )
    await axios.get(`/strapi/api/general-document?${query}`).then(
      (response) => {setCommitteePic(response.data.data);}).catch(e => console.log(e));
  }

  const getDocuments = async () => {
    const query = qs.stringify(
      {
        populate: { 
          peerSupporterImage: { fields: ['formats'] },
          juniorDeanImage: { fields: ['formats'] },
        }
      }
    )

    await axios.get(`/strapi/api/welfare-document?${query}`).then(
      (response) => {setDocuments(response.data.data);}).catch(e => console.log(e));
  };

  useEffect(() => {getDocuments(); getWCRCommitteePic();}, [])

  let commPicLink = default_img_src;
  let peerPicLink = default_img_src;
  let jdPicLink = default_img_src;

  if (committeePic.committeePicture) {
    commPicLink = committeePic.committeePicture.formats.small.url;
  }

  if (documents.peerSupporterImage) {
    peerPicLink = documents.peerSupporterImage.formats.thumbnail.url;
  }

  if (documents.juniorDeanImage) {
    jdPicLink = documents.juniorDeanImage.formats.thumbnail.url;
  }

  return (
    <>
      <TopBanner backgroundImage={TopBannerImage} title="Welfare" content="Welfare" />

      <ContentBlock title="We believe in welfare!">
        <p>The WCR is committed to contribute to your welfare during your time at the 
            University College. Managing your welfare means you are able to cope with all 
            aspects of your life, and make the most of your potential to play a full parts 
            in your community (WHO '18), hence also helping our graduate community to strive.
        </p>
      </ContentBlock>

      <ContentBlock title="What do we offer?">
        <p>We believe that most problems are easier to manage at an earlier stage. Our 
          long-standing partnerships with the college, university and Student Union (SU) 
          enable us to proactively address your welfare concerns.
        </p>
        <h2>Who can I talk to?</h2>
        <p>Please do refer to our Welfare FAQ for specific concerns. In general, you are 
          encouraged to reach out to your college advisors, as well as any college 
          faculties/staff that are responsible to specific welfare issues. If you do not 
          feel comfortable to directly contacting the college, then please contact any 
          one of us below:
        </p> 
        <div className="u-gridPic-3">
            <Image title="WCR Committee members" 
                  src={commPicLink}
                  opacity={.5}
                  isBlack={Boolean(true)}
                  aspect="16/9"
                  suppressArrow={Boolean(false)}
                  des="The Welfare Officer and other Committee members could act as your first contact for your welfare concerns."
                  linkdes="/about/governance"
                  isLocal={Boolean(true)}
                  altText="Photo of all WCR committees"/>
            <Image title="Peer supporters" 
                  src={peerPicLink}
                  opacity={.5}
                  isBlack={Boolean(true)}
                  aspect="16/9"
                  suppressArrow={Boolean(false)}
                  des="They are well-trained students to address your specific welfare concerns."
                  linkdes={documents.peerSupporter}
                  isLocal={Boolean(false)}
                  altText="Photo of all peer supporters"
                  />
            <Image title="Junior Deans" 
                  src={jdPicLink}
                  opacity={.5}
                  isBlack={Boolean(true)}
                  aspect="16/9"
                  suppressArrow={Boolean(false)}
                  des="Junior Deans assist the Dean in maintaining non-academic discipline, and offer welfare support to students."
                  linkdes={documents.juniorDean}
                  isLocal={Boolean(false)}
                  altText="Photo of all peer supporters"
                  />
          </div>
          {/* TODO: add junior dean. */}
        <ButtonFlex display={[
          // {key: 1, isLocal: true, des: "Welfare FAQ", link: "/welfareFAQ"},
          {key: 2, isLocal: false, des: "Official welfare guide", link: documents.welfareOverview},
          {key: 3, isLocal: false, des: "Where to look for help?", link: documents.whereToGetHelp}
        ]}/>
      </ContentBlock>

      <ContentBlock title="Welfare activities">
        <p>The WCR regularly holds activities to help you to adopt your university life, 
          as well as to relax whenever you feel stressed with academic/life concerns.
        </p>
        <div className="u-gridPic-3">
          <Image title="Welfare week" 
                src={WelfareWeekImage}
                opacity={.5}
                isBlack={Boolean(true)}
                aspect="16/9"
                suppressArrow={Boolean(false)}
                des="The WCR and the college organise different activities during the 
                mid-term to help you relax."
                linkdes="/events"
                isLocal={Boolean(true)}
                altText="Someone pouring coffee"/>
          <Image title="Yoga sessions" 
                src={YogaImage}
                opacity={.5}
                isBlack={Boolean(true)}
                aspect="16/9"
                suppressArrow={Boolean(false)}
                des="We host occasional Yoga sessions, guided by the professionals, to 
                help you relax by enjoying Yoga."
                linkdes="/events"
                isLocal={Boolean(true)}
                altText="Photo of Yoga"
                />
          <Image title="Sunday socials" 
                src={EuroVisionImage}
                opacity={.5}
                isBlack={Boolean(true)}
                aspect="16/9"
                suppressArrow={Boolean(false)}
                des="The Sunday socials are excellent opportunities for you to connect 
                with your graduates and enjoy some delicious snacks and drinks."
                linkdes="/events"
                isLocal={Boolean(true)}
                altText="Photo of WCR members watching eurovision"
                />
        </div>
      </ContentBlock>
    </>
  );
}

export default Welfare;