import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
// import { Fade } from "react-awesome-reveal";

import "../../utilities.css"
import "./Contact.css";

import TopBanner from "../modules/TopBanner.js";
import TopBannerImage from "../../img/topbanner/temp_univ_wall.jpg";
import ContentBlock from "../modules/ContentBlock.js";
import { scrollToTop } from "../../utilities.js";

const Contact = () => {
  const [socials, setSocials] = useState({})
  const getSocials = async () => {
    await axios.get('/strapi/api/social-media').then(
      (res) => setSocials(res.data.data)
    ).catch(
      e => console.log(e)
    )
  }

  useEffect(() => {getSocials();}, []);

  return (
    <>
    <TopBanner 
      backgroundImage={TopBannerImage}
      bgColorKey="black" 
      title="Contact" 
      content="Contact Us" />
    {/* <Fade direction="up" triggerOnce> */}
    <ContentBlock title="Contact Us">
      <p>Contact details for individual committees are available at the <Link to="/about/governance" onClick={scrollToTop}>Governance page</Link>. 
      You can also contact us by one of the following:</p>
      <ul>
        {socials.instagram && <li>message us directly (DM) at our <a href={socials.instagram}>official Instagram</a></li>}
        {socials.twitter && <li>tweet us at our <a href={socials.twitter}>official Twitter</a></li>}
        {socials.facebook && <li>join our <a href={socials.facebook}>official Facebook page</a></li>}
      </ul>
    </ContentBlock>
    {/* </Fade> */}
    </>
  );
};

export default Contact;
  