import React, { useState, useEffect } from "react";
import axios from "axios";
import qs from "qs";
// import { Fade } from "react-awesome-reveal";

import "../../../utilities.css"
import "./Governance.css";

import TopBanner from "../../modules/TopBanner.js";
import ContentBlock from "../../modules/ContentBlock.js";
import AllComms from "../../modules/AllComms.js";
import ButtonFlex from "../../modules/ButtonFlex.js";

const Governance = () => {
  const [currentComm, setCurrentComm] = useState([]);
  const [prevComm, setPrevComm] = useState([]);
  const [documents, setDocuments] = useState({});

  const getCurrentComm = async () => {
    const query = qs.stringify({
      filters: { wcrRole: { $eq: 'Committee' } },
      sort: ['preferredName'],
      populate: {
        profilePicture: { fields: ['url'] },
        committeePosts: { fields: ['name', 'rank'] },
      },
      pagination: { limit: 50 }
    })

    await axios.get(`/strapi/api/profiles?${query}`
      ).then((res) => {setCurrentComm(res.data.data);}).catch(e => {console.log(e)});
  }

  const getPrevComm = async () => {
    const query = qs.stringify({
      filters: { wcrRole: { $eq: 'Previous Committee' } },
      sort: ['preferredName'],
      populate: {
        profilePicture: { fields: ['url'] },
        committeePosts: { fields: ['name', 'rank'] },
      },
      pagination: { limit: 50 }
    })

    await axios.get(`/strapi/api/profiles?${query}`
      ).then((res) => {setPrevComm(res.data.data);}).catch(e => {console.log(e)});
  }

  const getDocuments = async () => {
    const query = qs.stringify({
      fields: ['constitution', 'constitutionPDF'],
      populate: {
        committeePicture: {
          fields: ['formats', 'url']
        }
      }
    })

    await axios.get(`/strapi/api/general-document?${query}`
      ).then((res) => {setDocuments(res.data.data)}
      ).catch(e => {console.log(e)});
  }

  useEffect(() => {getCurrentComm(); getPrevComm(); getDocuments()}, []);

  let committeePic = "";

  if (documents.committeePicture) {
    committeePic = documents.committeePicture.formats.large.url;
  }

  return (<>
    <TopBanner backgroundImage={committeePic} title="About" content="Governance" />

    {/* <Fade cascade={true} direction="up" triggerOnce> */}
    <ContentBlock title="Governance">
      <p>The WCR is governed by a committee of graduate students who advocate for and 
        represent the interests of the graduate student body within the College, University 
        and the Oxford SU. The committee also runs exciting social events (including BOPs) 
        and provides supports to the College postgraduate community.
      </p>
      <p>
        The WCR committee binds with the constitution, and is elected at the end of 
        the Hilary (second) Term each year.
      </p>
      <ButtonFlex display={[
          {key: 1, isLocal: false, des: "Constitution (HTML)", link: documents.constitution},
          {key: 2, isLocal: false, des: "Constitution (Official PDF)", link: documents.constitutionPDF},
        ]}/>
    </ContentBlock>
    <ContentBlock title="Current Committee Members">
      <AllComms commArr={currentComm} />
    </ContentBlock>
    <ContentBlock title="Past Committee Members">
      <p>We thank the previous committee members in contributing to the success of our common rooms.</p>
      <AllComms commArr={prevComm} />
    </ContentBlock>
    {/* </Fade> */}
    </>
  );
}

export default Governance;