import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import qs from "qs";
// import { Fade } from "react-awesome-reveal";

import "../../utilities.css"
import "./Home.css";

import TopBanner from "../modules/TopBanner.js";
import ButtonFlex from "../modules/ButtonFlex.js";
import ContentBlock from "../modules/ContentBlock.js";
import AllNews from "../modules/AllNews.js";
import AllEvents from "../modules/AllEvents.js";
import { scrollToTop } from "../../utilities.js";

import TopBannerImage from "../../img/topbanner/temp_eurovision.png";
import QuickLinks from "../modules/QuickLinks.js";

const Home = () => {
  const [news, setNews] = useState([]);
  const [events, setEvents] = useState([]);
  const [statusToColor, setStatusToColor] = useState({});
  const [quickLinks, setQuickLinks] = useState([]);

  const getNews = async () => {
    const query = qs.stringify({
      sort: ['updatedAt:desc'],
      pagination: {limit: 3},
      populate: {
        banner: {
          fields: ['alternativeText', 'url'],
        },
      },
    });

    const response = await axios.get(`/strapi/api/news?${query}`);
    setNews(response.data.data);
  };

  const getEvents = async () => {
    const query = qs.stringify({
      sort: ['startDate'],
      pagination: {limit: 200},
      populate: {
        banner: {
          fields: ['alternativeText', 'url'],
        },
        eventCategory: {
          fields: ['name', 'lightBkgColor']
        },
        natures: {
          fields: ['name', 'bkgColor', 'textColor'],
        },
      },
    });

    const response = await axios.get(`/strapi/api/events?${query}`);
    setEvents(response.data.data);
  };

  const getStatusColor = async () => {
    const query = qs.stringify({
      sort: "name:asc",
      fields: ['name', 'bkgColor']
    })
    
    await axios.get(`/strapi/api/event-statuses?${query}`
      ).then((response) => {
        const catsObj = response.data.data
        const colorObj = catsObj.reduce((acc, { name, bkgColor }, index) => {
        acc[name] = { bkgColor: bkgColor };
        return acc;
      }, {})
      setStatusToColor(colorObj);
    })
  };

  const getQuickLink = async () => {
    await axios.get(`/strapi/api/quick-links`
      ).then((response) => {setQuickLinks(response.data.data);
    })
  }

  useEffect(() => {
    getNews(); 
    getStatusColor().then(getEvents());
    getQuickLink();
  }, []);

  const todayDate = new Date();

  return (
    <>
      <TopBanner 
        backgroundImage={TopBannerImage} 
        bgColorKey="black" 
        title="Home" 
        content="Welcome to the University College WCR" />
      { // only render freshers' page button from August to October
        ((todayDate.getMonth() > 6) & (todayDate.getMonth() < 10)) ?  
          <ContentBlock title="Freshers' Updates">
            <div>
              We are glad that you are joining us this year! Click below to view the latest information for your arrival. 
              Please also check out our <Link to="/freshers" onClick={scrollToTop}>dedicated freshers' page</Link>.
            </div>
            <ButtonFlex display={[
              {key: 1, isLocal: true, des: "WCR Freshers' Page", link: "/freshers"},
              {key: 2, isLocal: false, des: "College New Student Page", link: "https://www.univ.ox.ac.uk/learn-at-univ/new-students"}
            ]}/>
          </ContentBlock> : <></>
      }

      <ContentBlock title="News">
        <AllNews newsArr={news} />
      </ContentBlock>

      <ContentBlock title="Upcoming Events">
        <div>For more events, please visit our <Link to="/events" onClick={scrollToTop}>list of events</Link> page 
          or check out our <Link to="/termcard" onClick={scrollToTop}>WCR term card</Link>.
        </div>
        <AllEvents 
          eventsArr={events}
          natureFltr={{}}
          catFltr={{}}
          statusToColor={statusToColor}
        />
      </ContentBlock>

      <ContentBlock title="Quick Links">
        <QuickLinks quickLinks={quickLinks}/>
      </ContentBlock>
    </>
  );
}

export default Home;
