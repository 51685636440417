import React from "react";
import Image from "../modules/Image.js";
import EventShortDes from "../modules/EventShortDes.js";

import default_img_src from "../../img/logo/univ_192x192.png";

import "./AllEvents.css"
import "../../utilities.css"

const currentTime = new Date()

const AllEvents = ({eventsArr, natureFltr, catFltr, statusToColor}) => (
  <section className="u-gridPic-3">
    {eventsArr.filter(
      (item) => {
        if (item.endDate) {
          // if ending time is defined, display events before ending time
          return new Date(item.endDate) > currentTime
        } else {
          // otherwise, display events before (starting time + 30mins)
          return new Date(item.startDate) > new Date(currentTime.getTime() - 30*60000);
        }
      }).filter(
        // filter by nature
        (item) => {
        // if no filter turns on then return all true
        if (!Object.values(natureFltr).some(item => item.active)) {
          return true
        } else {
          return item.natures.some((oneNature) => natureFltr[oneNature.name].active)
        }
      }).filter(
        // filter by category
        (item) => {
        // if no filter turns on then return all true
        if (!Object.values(catFltr).some(item => item.active)) {
          return true
        } else {
          return catFltr[item.eventCategory.name].active
        }
      }).map(
        (item) => {
        return (
            <Image 
            key={item.id}
            title="" 
            src={item.banner ? item.banner.url : default_img_src}
            opacity={item.banner ? 1 : .5}
            isBlack={Boolean(true)}
            aspect="16/9"
            suppressArrow={Boolean(true)}
            des={<EventShortDes attributes={item} statusToColor={statusToColor}/>}
            desBkg={`${item.eventCategory.lightBkgColor}4C`}
            linkdes={`/events/${item.shortTitle}`}
            isLocal={Boolean(true)}
            altText={item.banner ? item.banner.alternativeText : ""}/>
        )}
    )}
  </section>
)

export default AllEvents