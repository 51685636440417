import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import qs from "qs";
// import { Fade } from "react-awesome-reveal";

import axios from "axios";
import TopBanner from "../../modules/TopBanner.js";
import ContentBlock from "../../modules/ContentBlock.js";
import FilterMenu from "../../modules/FilterMenu.js";
import AllEvents from "../../modules/AllEvents.js";
import { scrollToTop } from "../../../utilities.js";

import food_in_wcr from "../../../img/topbanner/food_in_wcr.png";

import "../../../utilities.css"
import "./WhatsOn.css";

const WhatsOn = () => {
  const [events, setEvents] = useState([]);

  const getEvents = async () => {
    const query = qs.stringify({
      sort: ['startDate'],
      pagination: {limit: 200},
      populate: {
        banner: {
          fields: ['url', 'alternativeText']
        },
        natures: {
          fields: ['name', 'bkgColor', 'textColor']
        },
        eventCategory: {
          fields: ['name', 'lightBkgColor', 'textColor']
        }
      },
    });

    const response = await axios.get(`/strapi/api/events?${query}`);
    setEvents(response.data.data);
  };

  const [natureFltr, setNatureFltr] = useState({});
  const [catFltr, setCatFltr] = useState({});
  const [statusToColor, setStatusToColor] = useState({});

  const getNatures = async () => {
    const query = qs.stringify({
      sort: "name:asc",
      fields: ['name', 'bkgColor', 'textColor']
    })
    
    await axios.get(`/strapi/api/tags?${query}`
      ).then((response) => {
        const naturesObj = response.data.data
        const defaultNatureFilters = naturesObj.reduce((acc, { name, bkgColor, textColor }, index) => {
        acc[name] = {
          active: false,
          bkgColor: bkgColor,
          textColor: textColor
        };
        return acc;
      }, {})
      setNatureFltr(defaultNatureFilters);
    })
  }

  const getEventCategories = async () => {
    const query = qs.stringify({
      sort: "name:asc",
      fields: ['name', 'bkgColor', 'textColor']
    })
    
    await axios.get(`/strapi/api/event-categories?${query}`
      ).then((response) => {
        const catsObj = response.data.data
        const defaultCategoryFilters = catsObj.reduce((acc, { name, bkgColor, textColor }, index) => {
        acc[name] = {
          active: false,
          bkgColor: bkgColor,
          textColor: textColor
        };
        return acc;
      }, {})
      setCatFltr(defaultCategoryFilters);
    })
  }

  const getStatusColor = async () => {
    const query = qs.stringify({
      sort: "name:asc",
      fields: ['name', 'bkgColor']
    })
    
    await axios.get(`/strapi/api/event-statuses?${query}`
      ).then((response) => {
        const catsObj = response.data.data
        const colorObj = catsObj.reduce((acc, { name, bkgColor }, index) => {
        acc[name] = { bkgColor: bkgColor };
        return acc;
      }, {})
      setStatusToColor(colorObj);
    })
  }

  useEffect(() => {getNatures().then(
                    getEventCategories()
                  ).then(
                    getStatusColor()
                  ).then(
                    getEvents()
                  )}, []);

  const updateNatureFilter = (f) => {
    let newNatureFltr = { ...natureFltr }
    newNatureFltr[f].active = !newNatureFltr[f].active
    setNatureFltr(newNatureFltr)
  }

  const updateCatFilter = (f) => {
    let newCatFltr = { ...catFltr }
    newCatFltr[f].active = !newCatFltr[f].active
    setCatFltr(newCatFltr)
  }

  return (
    <>
      <TopBanner
        backgroundImage={food_in_wcr}
        bgColorKey="black"
        title="Events" 
        content="List of Events" />
        
      <ContentBlock title="Events">
        <p>Click on the event thumbnail (image) to view further event details. 😊
          You can now add the events into your Google or Microsoft 365 (Outlook) calendars, provided you have logged into any of those accounts. 
          However, please do check this page regularly for any event updates!</p>
        <p>You can also check out our <Link to="/termcard" onClick={scrollToTop}>recent term card</Link>, although this might not be updated.</p>
        <p>You can use the filters below to facilitate your search.</p>
        <section className="u-gridPic-2 whatson-filters">
          <div>
            <h3>Filter by the nature of events:</h3>
            <FilterMenu 
              filter={natureFltr}
              updateFilter={updateNatureFilter}
            />
          </div>
          <div>
            <h3>Filter by organiser:</h3>
            <FilterMenu 
              filter={catFltr}
              updateFilter={updateCatFilter}
            />
          </div>
        </section>

        <AllEvents 
          eventsArr={events}
          natureFltr={natureFltr}
          catFltr={catFltr}
          statusToColor={statusToColor}
        />

      </ContentBlock>
    </>
  );
};
  
export default WhatsOn;
