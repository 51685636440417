import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import qs from "qs";

import "../../../utilities.css"
import "./About.css";

import TopBanner from "../../modules/TopBanner.js";
import ContentBlock from "../../modules/ContentBlock.js";
import { scrollToTop } from "../../../utilities.js";
import Image from "../../modules/Image.js";

import TopBannerImage from "../../../img/topbanner/temp_mcr_room.jpg";
import MitchellBuilding from "../../../img/topbanner/mitchell_building.jpg";
import tempBoatHouse from "../../../img/topbanner/temp_boat_house_gathering.jpg";
import default_img_src from "../../../img/logo/univ_192x192.png";

const About = () => {
  const [committeePic, setCommitteePic] = useState({});

  const getWCRCommitteePic = async () => {
    const query = qs.stringify(
      {
        populate: { committeePicture: true }
      }
    )
    await axios.get(`/strapi/api/general-document?${query}`).then(
      (response) => {setCommitteePic(response.data.data);}).catch(e => console.log(e));
  }

  useEffect(() => {getWCRCommitteePic();}, []);

  let commPicLink = default_img_src;

  if (committeePic.committeePicture) {
    commPicLink = committeePic.committeePicture.formats.small.url;
  }

  return (
    <>
      <TopBanner backgroundImage={TopBannerImage} title="About" content="About" />

      <ContentBlock title="The graduate community of University College, Oxford.">
        <p>This Common Room was founded in the early 1960s to represent Univ graduate members. We are here to:</p>
        <ul>
          <li>to promote the interests and <Link to="/welfare" onClick={scrollToTop}>welfare of WCR members</Link>,</li>
          <li>to organise <Link to="/events" onClick={scrollToTop}>social activities</Link> among WCR members, and</li>
          <li>to represent the interests of WCR members to the Governing Body of the College.</li>
        </ul>
        <p>You can learn more about us by checking out the following pages:</p>
        <div className="u-gridPic-3">
          <Image title="Governance" 
                src={commPicLink}
                opacity={.5}
                isBlack={Boolean(true)}
                aspect="16/9"
                suppressArrow={Boolean(false)}
                des="The WCR is governed by a set of Constitution and its 
                  associated Standing Order. The WCR duties are carried out by
                  an annually-elected committee."
                linkdes="/about/governance"
                isLocal={Boolean(true)}
                altText="Photo of all WCR committees"/>
          <Image title="Building" 
                src={MitchellBuilding}
                opacity={.5}
                isBlack={Boolean(true)}
                aspect="16/9"
                suppressArrow={Boolean(false)}
                des="WCR events usually happens in the Mitchell Building."
                linkdes="/about/building"
                isLocal={Boolean(true)}
                altText="Photo of the Mitchell Building"
                />
          <Image title="General Meeting" 
                src={tempBoatHouse}
                opacity={.5}
                isBlack={Boolean(true)}
                aspect="16/9"
                suppressArrow={Boolean(false)}
                des="Important decisions are made by an all-member General Meeting. 
                Learn more about how you can participate in our democracy."
                linkdes="/about/general_meeting"
                isLocal={Boolean(true)}
                altText="Old members gathering in the College Boat House."
                />
          </div>
      </ContentBlock>
    </>
  );
}

export default About;
