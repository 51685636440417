import React from "react";
import SocietyBasicInfo from "../../components/modules/SocietyBasicInfo.js"

import "../../utilities.css";
import "./SocietyBanner.css";

/**
 * Profile Left is a component in profile displaying personal detail
 * of a user.
 *
 * Proptypes
 * @param societyObj for user object
 */

const SocietyBanner = ( {societyObj} ) => {
  let bgStyle = {};
  
  if (societyObj.banner) {
    bgStyle = {backgroundImage: `linear-gradient(rgba(0,0,0,0.5), 
       rgba(0,0,0,0.5)), 
       url(${societyObj.banner.url})`};
  } else {
    bgStyle = {
      backgroundColor: "black",
    };
  };
  
  return (
    <>
      <div className="societybanner" style={bgStyle}>
        <div className="societybanner-bodycontainer">
          <div className="societybanner-left">
            <h3 className="societybanner-title" style={{color: "white"}}>Societies</h3>
            <h1 className="societybanner-content" style={{color: "white"}}> 
              <span className="societybanner-line" style={{color: "white"}}></span>
              {societyObj.fullName}
            </h1>
          </div>
          <div className="societybanner-right">
          </div>
        </div>
      </div>
      <div className="societybanner-basicinfo">
        <SocietyBasicInfo societyObj={societyObj} />
      </div>
    </>
  );
};

export default SocietyBanner;