import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";
// import { Fade } from "react-awesome-reveal";

import TopBanner from "../../modules/TopBanner.js";
import ContentBlock from "../../modules/ContentBlock.js";
import { scrollToTop } from "../../../utilities.js";

import "../../../utilities.css"
import "./News.css";


const News = () => {
  let { updateShortTitle } = useParams();

  const [event, setEvent] = useState({});

  const getEvent = async () => {
    const query = qs.stringify({
      filters: { shortTitle: { $eq: updateShortTitle } },
      populate: {
        banner: { fields: ['url', 'alternativeText', 'caption']},
        author: {
          populate: {
            profilePicture: { fields: ['formats'] },
            committeePosts: { fields: ['name'] }
          }
        }
      }
    })

    await axios.get(`/strapi/api/news?${query}`
    ).then((res) => {setEvent(res.data.data[0]);}).catch(e => console.log(e))
  };

  useEffect(() => {getEvent();}, []);

  return (
    event.title ? (
    <>
      <TopBanner
        backgroundImage={event.banner.url}
        bgColorKey="black"
        title="News"
        content={event.title} />
      
      <ContentBlock title="Author">
        {event.author.map(author => (
          <Link 
            className="news-author" 
            key={author.id} 
            to={`/profile/${author.username}`}
            onClick={scrollToTop}
          >
            <div className="news-author-left">
              <img src={author.profilePicture.formats.thumbnail.url}/>
            </div>
            <div className="news-author-right">
              <h4 className="u-nomargin">{author.preferredName}</h4>
              <p className="u-nomargin">{author.committeePosts.map((item) => item.name).join()}, {author.wcrRole} <br /> {author.subject}</p>
            </div>
          </Link>
        ))}
      </ContentBlock>
      <ContentBlock title="Description">
        <Markdown>{event.content}</Markdown>
      </ContentBlock>
      { event.banner && (
          <>
            { event.banner.caption &&
              (<ContentBlock title="Photo Acknowledgement">
                <p>{event.banner.caption}</p>
              </ContentBlock>) 
            }
          </> )
      }
    </> ) : (
    <>
      <TopBanner title="News" content="Not found!" />
      <ContentBlock title="Your requested page could not be found.">
        <section>
        Please navigate to your desired page using the navigation bar.
        </section>
      </ContentBlock>
    </> )
  );
};
  
export default News;
