import React from "react";

import ProfileBasicInfo from "./ProfileBasicInfo.js";
import "../../utilities.css";
import "./ProfileBanner.css";

/**
 * Profile Left is a component in profile displaying personal detail
 * of a user.
 *
 * Proptypes
 * @param backgroundImage for link for background image
 * @param bgColorKey choice of primary, secondary, blue, violet, black
 * @param opacityLeft for background
 * @param opacityRight for background
 * @param textColor
 * @param userObj for user object
 */

const ProfileBanner = ({userObj}) => {

  return (
      <>
        <div className="profilebanner">
          <div className="profilebanner-bodycontainer">
            <div className="profilebanner-left">
              <h3 className="profilebanner-title">
                Profile
              </h3>
              <h1 className="profilebanner-content"> 
                <span className="profilebanner-line"></span>
                <>{userObj.preferredName} {userObj.preferredPronoun && (<>({userObj.preferredPronoun})</>)}</>
              </h1>
            </div>
            <div className="profilebanner-right">
            </div>
          </div>
        </div>
        <div className="profilebanner-basicinfo">
          <ProfileBasicInfo userObj={userObj} />
        </div>
      </>
    );
  }

export default ProfileBanner;