import React, { useState } from "react";
import { Link } from "react-router-dom";

import NavBarDropdown from "./NavBarDropdown.js";
import { scrollToTop } from "../../utilities.js";

import "../../utilities.css";
import "./NavBar.css";

import univLogo from "../../img/logo/univ_badge.png";

function NavBar () {
  const displayminwidth = 1160;

  // hamburger menu
  const [click, setClick] = useState(false);
  const [dropdownEvents, setDropdownEvents] = useState(false);
  const [dropdownSocieties, setDropdownSocieties] = useState(false);
  const [dropdownAbout, setDropdownAbout] = useState(false);
  // const [dropdownWelfare, setDropdownWelfare] = useState(false);

  const closeMobileMenu = () => setClick(false);
  
  const collapseAfterClick = () => {
    closeMobileMenu();
    scrollToTop();
  }

  // event + termcard pages

  const onMouseEnterEvents = () => {
    if (window.innerwidth < displayminwidth) {setDropdownEvents(false)} else {setDropdownEvents(true)};
  };

  const onMouseLeaveEvents = () => {setDropdownEvents(false)};

  const eventsSubpages = [
    {title: "Events", localpath: true, path: "/events"},
    {title: "Term Card", localpath: true, path: "/termcard"},
  ]

  // societies pages

  const onMouseEnterSocieties = () => {
    if (window.innerwidth < displayminwidth) {setDropdownSocieties(false)} else {setDropdownSocieties(true)};
  };

  const onMouseLeaveSocieties = () => {setDropdownSocieties(false)};

  const societiesSubpages = [
    {title: "A to Z", localpath: true, path: "/societies"},
    {title: "Support for societies", localpath: true, path: "/societies-supports"},
  ]

  // about and governance pages

  const onMouseEnterAbout = () => {
    if (window.innerwidth < displayminwidth) {setDropdownAbout(false)} else {setDropdownAbout(true)};
  };

  const onMouseLeaveAbout = () => {setDropdownAbout(false)};

  const aboutSubpages = [
    {title: "About Us", localpath: true, path: "/about"},
    {title: "Governance", localpath: true, path: "/about/governance"},
    {title: "Building", localpath: true, path: "/about/building"},
    {title: "General Meeting", localpath: true, path: "/about/general_meeting"},
    // {title: "Meeting records", localpath: true, path: ""},
  ]

  // welfare pages

  // const onMouseEnterWelfare = () => {
  //   if (window.innerwidth < displayminwidth) {setDropdownWelfare(false)} else {setDropdownWelfare(true)};
  // };

  // const onMouseLeaveWelfare = () => {setDropdownWelfare(false)};

  // const welfareSubpages = [
  //   {title: "Welfare FAQ", localpath: true, path: "/welfare/welfarefaq"},
  //   {title: "Who's who", localpath: true, path: "/welfare/whoswho"},
  // ]

  // for rendering freshers
  const todayDate = new Date();

  return (
    <>
      <nav className="navbar-container">
        <Link to="/" className="navbar-title" onClick={scrollToTop}>
          <img src={univLogo} className="navbar-logo" alt="martlet"/> 
          <div className="navbar-title-full">
            <p className="navbar-univ">University College, Oxford</p> 
            <p className="navbar-wcr">Weir Common Room</p>
          </div> 
          <div className="navbar-title-short">Univ WCR</div> 
        </Link>

        <div className="navbar-menu-icon" onClick={() => setClick(!click)}>
          <i className={click ? "fas fa-times" : "fas fa-bars"}/>
        </div>

        <ul className={click ? "navbar-menu active" : "navbar-menu"}>
          { // only render freshers' page button from August to October
            ((todayDate.getMonth() > 6) & (todayDate.getMonth() < 10)) ?  
            <li className="navbar-item" onClick={collapseAfterClick}> 
              <Link to="/freshers" className="navbar-links">
                Freshers
              </Link>
            </li> : <></>
          }

          <li className="navbar-item" onMouseEnter={onMouseEnterEvents} onMouseLeave={onMouseLeaveEvents}> 
            <Link to="/events" className="navbar-links" onClick={collapseAfterClick}>
              Events <i className="fas fa-caret-down"/> </Link>
              {dropdownEvents && <NavBarDropdown menuItems={eventsSubpages} />}
          </li>

          <li className="navbar-item" onClick={collapseAfterClick}> 
            <Link to="/news" className="navbar-links" onClick={scrollToTop}> 
              News
            </Link>
          </li>

          <li className="navbar-item" onMouseEnter={onMouseEnterSocieties} onMouseLeave={onMouseLeaveSocieties}> 
            <Link to="/societies" className="navbar-links" onClick={collapseAfterClick}>
              Societies <i className="fas fa-caret-down"/> </Link>
              {dropdownSocieties && <NavBarDropdown menuItems={societiesSubpages} />}
          </li>

          <li className="navbar-item" onMouseEnter={onMouseEnterAbout} onMouseLeave={onMouseLeaveAbout}> 
            <Link to="/about" className="navbar-links" onClick={collapseAfterClick}>
              About <i className="fas fa-caret-down"/> </Link>
              {dropdownAbout && <NavBarDropdown menuItems={aboutSubpages} />}
          </li>

          <li className="navbar-item" onClick={collapseAfterClick}
            // onMouseEnter={onMouseEnterWelfare} onMouseLeave={onMouseLeaveWelfare}
          > 
            <Link to="/welfare" className="navbar-links" onClick={scrollToTop}>
              Welfare 
              {/* <i className="fas fa-caret-down"/>  */}
            </Link>
              {/* {dropdownWelfare && <NavBarDropdown menuItems={welfareSubpages} />} */}
          </li>

          <li className="navbar-item"> 
            <Link to="/contact" className="navbar-links" onClick={collapseAfterClick}>Contact Us</Link></li>

          <li className="navbar-item"><a href="https://www.univ.ox.ac.uk/" className="navbar-links">College website <i className="fa-solid fa-arrow-up-right-from-square" /></a></li>
          {/* <li className="navbar-item"><a href="https://www.universitycollegeball.com" className="navbar-links">Univ Ball 2024 <i className="fa-solid fa-arrow-up-right-from-square" /></a></li> */}
        </ul>
      </nav>
    </>
  );
}

export default NavBar;