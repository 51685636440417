import React, { useState, useEffect } from "react";
import axios from "axios";
import qs from "qs";
import { Link } from "react-router-dom";
// import { Fade } from "react-awesome-reveal";

import "../../../utilities.css"
import "./GeneralMeeting.css";

import TopBanner from "../../modules/TopBanner.js";
import ContentBlock from "../../modules/ContentBlock.js";
import ButtonFlex from "../../modules/ButtonFlex.js";

import tempBoatHouse from "../../../img/topbanner/temp_boat_house_gathering.jpg";

const GeneralMeeting = () => {
  const [documents, setDocuments] = useState({});

  const getDocuments = async () => {
    const query = qs.stringify({
      fields: ['constitution', 'constitutionPDF'],
      populate: {
        committeePicture: {
          fields: ['formats', 'url']
        }
      }
    })

    await axios.get(`/strapi/api/general-document?${query}`
      ).then((res) => {setDocuments(res.data.data)}
      ).catch(e => {console.log(e)});
  }

  useEffect(() => {getDocuments()}, []);

  return (<>
    <TopBanner backgroundImage={tempBoatHouse} title="About" content="General Meeting" />

    {/* <Fade cascade={true} direction="up" triggerOnce> */}
    <ContentBlock title="General Meeting">
      <p>Decisions of the WCR, including governance changes and the funding of 
        societies, are determined by general meetings. These meetings are called by 
        the President (or anyone acting for this position), or a number of WCR full 
        members exceeding certain quorum. Details of these meetings will be 
        announced by email, and on social media (including this website) at the 
        discretion of <Link to="/about/governance">the Committee</Link>.
      </p>
      <p>
        You can participate by simply turning up, or propose a motion to mandate 
        the WCR to perform any actions. The rules of calling and conducting a 
        general meeting is provided in the Constitution and its associated Standing 
        Orders.
      </p>
      <ButtonFlex display={[
          {key: 1, isLocal: false, des: "Constitution (HTML)", link: documents.constitution},
          {key: 2, isLocal: false, des: "Constitution (Official PDF)", link: documents.constitutionPDF},
        ]}/>
    </ContentBlock>
    <ContentBlock title="Guide to writing a motion">
      <p>We recommend a motion to contain specific points of actions (known 
        as <i>resolutions</i>), as well as any reasonings. A typical motion 
        would therefore look like:</p>
      <h3>The WCR notes that:</h3>
      <p>This section contains factual information that supports/provides context 
        to the motion.
      </p>
      <h3>(Optional) The WCR believes that:</h3>
      <p>This section contains opinions that you wish the WCR to agree on, and if 
        the WCR agrees, would support the motion if agreed to.
      </p>
      <h3>The WCR resolves to:</h3>
      <p>This section provide specific action points for the WCR to deliver.</p>
      <p>Any motion must be seconded by another WCR member. You are strongly 
        recommended to discuss the motion with 
        the <Link to="/about/governance">Committee</Link> before submitting to 
        a General meeting. This would help us to assess any associated risks 
        and ensure that the WCR could deliver the action points in accordance with 
        the current legislations (including the WCR Constitution and other 
        College/University regulations) as soon as the motion is passed. 
      </p>
    </ContentBlock>
    {/* </Fade> */}
    </>
  );
}

export default GeneralMeeting;