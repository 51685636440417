// Utility functions
import qs from "qs";

// scrollToTop function
export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

// for the creation of deep links for Google and Outlook

const CatShorthands = {
  "College Official": "College",
  "Common Room": "WCR",
  "Student Society": "Society",
  "Other Initiatives": ""
}

const DateToGoogleStr = ( date ) => {
  // /[\.:-]/g is a regex, 
  // [] searching for symbols ., - and :
  // g refers to global search
  const dateStr = date.toISOString().split('.')[0]+"Z"  // remove milliseconds
  return dateStr.replace(/[.:-]/g, "")  // remove punctuation by replacing with empty string
}

const DateToOutlookStr = ( date ) => {
  // /[\.:-]/g is a regex, 
  // [] searching for symbols ., - and :
  // g refers to global search
  const dateStr = date.toISOString().split('.')[0]+"Z"  // remove milliseconds
  return dateStr
}

export const EventDeepLinks = ( {eventObj} ) => {
  
  // time object
  const startTimeObj = new Date(eventObj.startDate);
  let endTimeObj = new Date(startTimeObj.getTime() + 60 * 60000);

  if (eventObj.endDate) {
    endTimeObj = new Date(eventObj.endDate);
  }

  // include contact details in description
  let calendarDes = eventObj.description

  if (eventObj.otherContacts) {
    calendarDes += `\r\n \r\n If you wish to hear more about the event, you can contact: \n ${eventObj.otherContacts}`
  }

  // create Query strings
  const googleQueryStr = qs.stringify({
    action: "TEMPLATE",
    dates: `${DateToGoogleStr(startTimeObj)}/${DateToGoogleStr(endTimeObj)}`,
    details: calendarDes, // eventObj.description
    location: eventObj.firstVenue,
    text: `Univ ${CatShorthands[eventObj.category]}: ${eventObj.title}`,
  })

  const outlookQueryStr = qs.stringify({
    path: "/calendar/action/compose",
    rru: "addevent",
    allday: "false",
    startdt: DateToOutlookStr(startTimeObj),
    enddt: DateToOutlookStr(endTimeObj),
    subject: `Univ ${CatShorthands[eventObj.category]}: ${eventObj.title}`,
    body: calendarDes,
    location: eventObj.firstVenue,
  })

  // concatenate to find query URLS
  const googleQueryUrl = `https://calendar.google.com/calendar/render?${googleQueryStr}`;
  const outlookQueryUrl = `https://outlook.office365.com/calendar/deeplink/compose?${outlookQueryStr}`

  return {googleQueryUrl, outlookQueryUrl}
}

