import React from "react";
import { Link } from "react-router-dom";

import "../../utilities.css"
import "./AllComms.css";

import default_img_src from "../../img/logo/univ_192x192.png";

import { scrollToTop } from "../../utilities.js";

const AllComms = ({commArr}) => {

  // sort by position
  let currentComm = commArr.sort(
    (comm1, comm2) => {
      let order1 = 100;
      let order2 = 100;

      if (comm1.committeePosts) {
        // see MDN Web docs on how to obtain minimum of an array
        order1 = Math.min(...comm1.committeePosts.map((item) => item.rank));
      }

      if (comm2.committeePosts) {
        order2 = Math.min(...comm2.committeePosts.map((item) => item.rank));
      }

      return (order1 - order2)
    }
  )

  return (
    <div className="AllComms-container">
      { 
        currentComm.map(
          (comm) => { 
          let bG
          if (comm.profilePicture) {
            bG = `url("${comm.profilePicture.url}")`;
          } else {
            bG = `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("${default_img_src}")`;
          }

          return (
            <div key={comm.id} className="AllComms-single">
              <Link to={`/profile/${comm.username}`} onClick={scrollToTop}>
              <div className="AllComms-background" style={{backgroundImage: bG}}> </div>
              <div className="AllComms-about"> 
                  <div className="name"> <b>{comm.preferredName}</b> {comm.preferredPronoun && <>({comm.preferredPronoun})</>} </div>
                  <div className="post"> {comm.committeePosts.map((item) => item.name).join()} </div>
              </div>
              </Link>
            </div>
          )
        })}
    </div>
  );
};
  
export default AllComms;