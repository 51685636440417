import React, {useState, useEffect} from "react";
import qs from "qs";
// import { Fade } from "react-awesome-reveal";

import axios from "axios";
import ContentBlock from "../../modules/ContentBlock.js";
import TopBanner from "../../modules/TopBanner.js";
import AllNews from "../../modules/AllNews.js";

import TopBannerImage from "../../../img/topbanner/temp_univ_goose.jpg";

import "../../../utilities.css"
import "./Update.css";

const Update = () => {
  const [news, setNews] = useState([]);

  const getNews = async () => {
    const query = qs.stringify({
      sort: ['updatedAt:desc'],
      pagination: {limit: 25},
      populate: {
        banner: {
          fields: ['url', 'alternativeText']
        },
      },
    })
    const response = await axios.get(`/strapi/api/news?${query}`);
    setNews(response.data.data);
  };

  useEffect(() => {getNews();}, []);

  return (
    <>
      <TopBanner
        backgroundImage={TopBannerImage}
        bgColorKey="black"
        title="News" 
        content="Common Room News" />
      
      <ContentBlock title="All News Stories">
        <AllNews newsArr={news} />
      </ContentBlock>
    </>
  );
};
  
export default Update;
