import React from "react";

import "../../utilities.css"
import "./QuickLinks.css";

const QuickLinks = ({quickLinks}) => {
    return (
      <div className="u-gridPic-3">
        {quickLinks.sort((item1, item2) => {return (item1.rank - item2.rank)}
          ).map((item) => {
            return (
            <a key={item.id} href={item.link} className="quicklinks">
                <div className="quicklinks-des">{item.description}</div>
                <div className="quicklinks-link">{item.link}</div>
            </a>
          )}
        )
        }
      </div>
    )
}

export default QuickLinks;