import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import Markdown from "react-markdown";
// import { Fade } from "react-awesome-reveal";

import SocietyBanner from "../../modules/SocietyBanner.js";
import TopBanner from "../../modules/TopBanner.js";
import ContentBlock from "../../modules/ContentBlock.js";
import AllEvents from "../../modules/AllEvents.js";

import "../../../utilities.css"
import "./Society.css";

const Society = () => {
  let { abbreviation } = useParams();
  const [society, setSociety] = useState({});
  const [events, setEvents] = useState([]);
  const [statusToColor, setStatusToColor] = useState({});

  const getSociety = async () => {
    const query = qs.stringify({
      filters: { abbreviation: { $eq: abbreviation } },
      populate: {
        president: { fields: ['Name', 'Email', 'Website'] },
        wcr_contacts: { fields: ['Name', 'Email', 'Website'] },
        banner: { fields: ['alternativeText', 'url', 'caption'] },
        societyNature: { fields: ['name', 'bkgColor', 'textColor'] },
      },
    });

    await axios.get(`/strapi/api/societies?${query}`
    ).then(res => setSociety(res.data.data[0])).catch((e) => console.log(e));
  };

  // obtain society related event separately to limit pagination
  const getSocietyEvents = async () => {
    const query = qs.stringify({
      sort: ['startDate'],
      pagination: {limit: 3},
      populate: {
        banner: { fields: ['alternativeText', 'url'] },
        eventCategory: { fields: ['name', 'lightBkgColor'] },
        natures: { fields: ['name', 'bkgColor', 'textColor'] },
        organisingSocieties: { fields: ['abbreviation'] }
      },
      filters: {
        organisingSocieties: { abbreviation: { "$eq": abbreviation } }
      }
    });

    await axios.get(`/strapi/api/events?${query}`
    ).then(res => setEvents(res.data.data)).catch((e) => console.log(e));
  };

  const getStatusToColor = async () => {
    const query = qs.stringify({
      sort: "name:asc",
      fields: ['name', 'bkgColor']
    })
    
    await axios.get(`/strapi/api/event-statuses?${query}`
      ).then((response) => {
        const catsObj = response.data.data
        const colorObj = catsObj.reduce((acc, { name, bkgColor }, index) => {
        acc[name] = { bkgColor: bkgColor };
        return acc;
      }, {})
      setStatusToColor(colorObj);
    })
  };

  useEffect(() => {getSociety(); getStatusToColor().then(getSocietyEvents());}, []);

  return (
    society.fullName ? (
      <>
        <SocietyBanner societyObj={society} />

        <ContentBlock title="Description">
          <section className="society-intro">
            <Markdown>{society.description}</Markdown>
          </section>
        </ContentBlock>

        {
          society.regularSchedule && (<ContentBlock title="Meeting schedule for this term">
          <section className="society-intro">
            <Markdown>{society.regularSchedule}</Markdown>
          </section>
          </ContentBlock>)
        }
        
        {
          society.fullCommittee && (
            <ContentBlock title="Committee Details">
              <Markdown>{society.fullCommittee}</Markdown>
            </ContentBlock>
          )
        }

        { society.banner && (
          <>
            { society.banner.caption &&
              (<ContentBlock title="Photo Acknowledgement">
                <p>{society.banner.caption}</p>
              </ContentBlock>) 
            }
          </> )
        }

        { events && (
          <ContentBlock title="Upcoming Events">
            <AllEvents 
              eventsArr={events}
              natureFltr={{}}
              catFltr={{}}
              statusToColor={statusToColor}
            />
          </ContentBlock> )
        }
      </>
    ) : (
      <>
        <TopBanner title="Society" content="Society not found!" />
          <ContentBlock title="Your requested event could not be found.">
            <section>
            Please navigate to your desired page using the navigation bar.
            </section>
          </ContentBlock>
          </>
    )
  );
};
  
export default Society;
