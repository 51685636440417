import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import qs from "qs"
import Markdown from "react-markdown";

import "../../utilities.css"
import "./Profile.css";

import AllNews from "../modules/AllNews.js";
import AllSocieties from "../modules/AllSocieties.js";
import TopBanner from "../modules/TopBanner.js";
import ProfileBanner from "../modules/ProfileBanner.js";
import ContentBlock from "../modules/ContentBlock.js";

const Profile = () => {
  let { userName } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [news, setNews] = useState([]);

  const getUser = async () => {
    setIsLoading(true);

    const userQuery = qs.stringify({
      filters: { username: { $eq: userName, }, },
      populate: {
        profilePicture: { fields: ['url', 'alternativeText'] },
        univEngagements: true,
        committeePosts: { fields: ['name'] },
        activeSocieties: { 
          populate: { 
            banner: {
              fields: ['alternativeText', 'url'],
            },
            societyNature: {
              fields: ['name', 'bkgColor', 'textColor']
            },
          },
        },
      },
    })

    const newsQuery = qs.stringify({
      sort: ['updatedAt:desc'],
      pagination: {limit: 3},
      populate: {
        author: { fields: ['username'] },
        banner: { fields: ['alternativeText', 'url'], },
      },
      filters: {
        author: { username: { $eq: userName }},
      }
    })

    await axios.get(`/strapi/api/profiles?${userQuery}`).then(
      (resUser) => {setCurrentUser(resUser.data.data[0]);}
    );

    await axios.get(`/strapi/api/news?${newsQuery}`).then(
      (resNews) => {setNews(resNews.data.data);}
    );
    
    setIsLoading(false);
  };

  useEffect(() => {getUser();}, []);

  if (isLoading) {
    <>
      <TopBanner title="" content="Loading!" />
      <section className="u-block">Thank you for your patience.</section>
    </>
  }

  if (!currentUser) {
    return (
      <>
        <TopBanner title="" content="This profile does not exist." />
        <ContentBlock title="Please wait patiently as the website loads">
          <section>
            Please <Link to="/contact">contact us</Link> if the problem still persist.
          </section>
        </ContentBlock>
      </>
    )
  }
  
  return (
    <>
      <ProfileBanner title="Profile" userObj={currentUser} bgColorKey="secondary" />
      <ContentBlock title="Univ Engagement">
        <section className="profile-engagement-container">
          {currentUser.univEngagements.sort((item1, item2) => {return (item2.startDate > item1.startDate)}).map((item, idx) => {
            const startYear = new Date(item.startDate);
            const endYear = item.endDate ? new Date(item.endDate) : "";

            return (
              <div key={idx} className="profile-engagement"> 
                {item.name}, {item.organisation} {startYear.getFullYear()}-
                {endYear ? (<>{endYear.getFullYear()}</>) : (<></>)}</div>
                );
              }
            )
          }
        </section>
      </ContentBlock>

      <ContentBlock title="Personal introduction">
        <section className="profile-intro">
          <Markdown>{currentUser.personalIntro}</Markdown>
        </section>
      </ContentBlock>

      { news[0] && (
          <ContentBlock title="Recent News">
            <AllNews newsArr={news} />
          </ContentBlock>
        )
      }

      { currentUser.activeSocieties[0] && (
          <ContentBlock title="Affiliated Societies">
            <AllSocieties 
              societiesArr={currentUser.activeSocieties}
              natureFltr={{}}
              search=""
            />
          </ContentBlock>
        )
      }
    </>
  );
};

export default Profile;