import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import NavBar from "./modules/NavBar.js";
import BottomBanner from "./modules/BottomBanner.js";

import NotFound from "./pages/NotFound.js";
import Accessibility from "./pages/Accessibility.js";

import Home from "./pages/Home.js";

import About from "./pages/about/About.js";
import Governance from "./pages/about/Governance.js";
import Building from "./pages/about/Building.js";
import GeneralMeeting from "./pages/about/GeneralMeeting.js";

import Profile from "./pages/Profile.js";

import Welfare from "./pages/welfare/Welfare.js";
// import WelfareFAQ from "./pages/welfare/WelfareFAQ.js";
// import WhosWho from "./pages/welfare/WhosWho.js";
import Freshers from "./pages/Freshers.js";

import WhatsOn from "./pages/events/WhatsOn.js";
import Event from "./pages/events/Event.js";
import TermCard from "./pages/TermCard.js";

import News from "./pages/news/News.js";
import Update from "./pages/news/Update.js";

import Society from "./pages/societies/Society.js";
import SocietyAZ from "./pages/societies/SocietyAZ.js";

import SupportSocieties from "./pages/SupportSocieties.js";

import Contact from "./pages/Contact.js";

import "../utilities.css";

const App = () => {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route exact path="/about" element={<About />} />
        <Route exact path="/about/building" element={<Building />} />
        <Route exact path="/about/governance" element={<Governance />} />
        <Route exact path="/about/general_meeting" element={<GeneralMeeting />} />
        <Route exact path="/profile/:userName" element={<Profile />} />
        <Route exact path="/welfare" element={<Welfare />} />
        <Route exact path="/freshers" element={<Freshers />} />
        {/* <Route exact path="/welfare/welfarefaq" element={<WelfareFAQ />} /> */}
        {/* <Route exact path="/welfare/whoswho" element={<WhosWho />} /> */}
        <Route exact path="/societies-supports" element={<SupportSocieties />} />
        <Route exact path="/societies/:abbreviation" element={<Society />} />
        <Route exact path="/societies" element={<SocietyAZ />} />

        <Route exact path="/news/:updateShortTitle" element={<News />} />
        <Route exact path="/news" element={<Update />} />
        
        <Route exact path="/events/:eventShortTitle" element={<Event />} />
        <Route exact path="/events" element={<WhatsOn />} />
        <Route exact path="/termcard" element={<TermCard />} />

        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/privacyaccessibility" element={<Accessibility />} />

        <Route exact path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <BottomBanner />
    </Router>
  );
};

export default App;
