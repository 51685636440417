import React from "react";
import { Link } from "react-router-dom";

import "../../utilities.css"
import "./AllSocieties.css";

import { scrollToTop } from "../../utilities.js";

import default_img_src from "../../img/logo/univ_192x192.png";

const AllSocieties = ({ societiesArr, natureFltr, search }) => {
  return (
    <div className="AllSocieties-container">
        {societiesArr.filter(
          // filter by nature
          (soc) => {
            // if no filter turns on then return all true
            if (!Object.values(natureFltr).some(item => item.active)) {
              return true
            } else {
              return natureFltr[soc.societyNature.name].active
            }
          }
        ).filter(
          (soc) => {
            // if no filter turns on then return all true
            if (!search) {
              return true
            } else {
              return soc.shortName.toLowerCase().includes(search.toLowerCase()) || soc.fullName.toLowerCase().includes(search.toLowerCase());
            }
          }
        ).map(
          (soc) => { 
          
          let bG
          
          if (soc.banner) {
            bG = `url("${soc.banner.url}")`;
          } else {
            bG = `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("${default_img_src}")`;
          }

          return (
            <div key={soc.id} className="AllSocieties-single">
              <Link to={`/societies/${soc.abbreviation}`} onClick={scrollToTop}>
              <div className="AllSocieties-background" style={{backgroundImage: bG}}> </div>
              <div className="AllSocieties-about"> 
                <div className="AllSocieties-name"> 
                  <b>{soc.shortName} </b> 
                  <span className="AllSocieties-nature" style={{
                    backgroundColor: soc.societyNature.bkgColor,
                    color: soc.societyNature.textColor,
                  }}>{soc.societyNature.name}</span>
                </div>
              </div>
              </Link>
            </div>
          )
        })}
    </div>
  );
};
  
export default AllSocieties;