import React from "react";
import EventBasicInfo from "./EventBasicInfo.js";

import "../../utilities.css";
import "./EventBanner.css";

/**
 * EventBanner is a component in profile displaying details of a WCR event.
 *
 * Proptypes
 * @param {Object} eventObj for a single event object.
 * @param {Object} statusToColor for color of status bar.
 */

const EventBanner = ( {eventObj, statusToColor} ) => {
  const actualBkgColor = eventObj.eventCategory.bkgColor || "#454545";
  const actualColor = eventObj.eventCategory.textColor || "#FFF";
  
  let bgStyle = {};
  
  try {
    bgStyle = {backgroundImage: `linear-gradient(${actualBkgColor}D0, ${actualBkgColor}D0), 
      url(${eventObj.banner.url})`};
  } catch {
    bgStyle = {
      backgroundColor: `${actualBkgColor}`,
    };
  }
  
  return (
    <>
      <div className="eventbanner" style={bgStyle}>
        <div className="eventbanner-bodycontainer">
          <div className="eventbanner-left">
            <h3 className="eventbanner-title" style={{color: actualColor}}>Events</h3>
            <h1 className="eventbanner-content" style={{color: actualColor}}> 
              <span className="eventbanner-line" style={{color: actualColor}}></span>
              {eventObj.title} 
            </h1>
          </div>
          <div className="eventbanner-right">
          </div>
        </div>
      </div>
      <div className="eventbanner-basicinfo">
        <EventBasicInfo eventObj={eventObj} statusToColor={statusToColor}/>
      </div>
    </>
  );
}

export default EventBanner;