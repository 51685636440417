import React from "react";

import Image from "../modules/Image.js";

import default_img_src from "../../img/logo/univ_192x192.png";

import "./AllNews.css"
import "../../utilities.css"

const AllNews = ({newsArr}) => {
    return (
        <div className="u-gridPic-3">
          {newsArr.map(
              (item) => {
                return (
                  <Image 
                    key={item.id}
                    title="" 
                    src={item.banner ? item.banner.url : default_img_src}
                    opacity={item.banner ? 1 : .5}
                    isBlack={Boolean(true)}
                    aspect="16/9"
                    suppressArrow={Boolean(true)}
                    des={<>
                      <h4 className="u-nomargin">{item.title}</h4>
                      <p>{item.content.slice(0, 85)}...</p>
                    </>}
                    linkdes={`/news/${item.shortTitle}`}
                    isLocal={Boolean(true)}
                    altText={item.banner ? item.banner.alternativeText : ""}/>
                )}
            )}
        </div>
    )
}

export default AllNews;