import React, { useState, useEffect } from "react";
import axios from "axios";
import qs from "qs";
// import { Fade } from "react-awesome-reveal";

import TopBanner from "../../modules/TopBanner.js";
import ContentBlock from "../../modules/ContentBlock.js";
import AllSocieties from "../../modules/AllSocieties.js";
import FilterMenu from "../../modules/FilterMenu.js";
import ButtonFlex from "../../modules/ButtonFlex.js";

import "../../../utilities.css"
import "./SocietyAZ.css";

import TopBannerImage from "../../../img/topbanner/univ_women_boat_club.jpg";

const SocietyAZ = () => {
  const [societies, setSocieties] = useState([]);
  const [natureFltr, setNatureFltr] = useState({});
  const [documents, setDocuments] = useState({});

  const getSocieties = async () => { 
    const query = qs.stringify({
      sort: ['shortName'],
      pagination: {limit: 75},
      populate: {
        banner: {
          fields: ['alternativeText', 'url'],
        },
        societyNature: {
          fields: ['name', 'bkgColor', 'textColor']
        }
      },
    });

    await axios.get(`/strapi/api/societies?${query}`).then(
      (resSocieties) => {setSocieties(resSocieties.data.data)}
    ).catch(e => {console.log(e)});
  };

  const getNatures = async () => {
    const query = qs.stringify({
      fields: ['name', 'bkgColor', 'textColor'],
      sort: ['name'],
    });

    await axios.get(`/strapi/api/society-tags?${query}`).then(
      (response) => {
        const naturesObj = response.data.data
        const defaultNatureFilters = naturesObj.reduce((acc, { name, bkgColor, textColor }, index) => {
        acc[name] = {
          active: false,
          bkgColor: bkgColor,
          textColor: textColor
        };
        return acc;
      }, {})
      setNatureFltr(defaultNatureFilters);
      }
    ).catch(e => {console.log(e)});
  }

  const getDocuments = async () => {
    await axios.get(`/strapi/api/general-document`).then(
      (res) => {setDocuments(res.data.data)}
    ).catch(e => {console.log(e)});
  }

  useEffect(() => {
    getNatures().then(getSocieties());
    getDocuments();
  }, []);

  const updateNatureFilter = (f) => {
    let newNatureFltr = { ...natureFltr }
    newNatureFltr[f].active = !newNatureFltr[f].active
    setNatureFltr(newNatureFltr)
  }

  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (<>
    <TopBanner 
      backgroundImage={TopBannerImage}
      bgColorKey="black"
      title="Societies" 
      content="List of College Societies" 
    />

    {/* <Fade cascade={true} direction="up" triggerOnce> */}
    <ContentBlock title="Societies">
      <p>At Univ, there are many student college societies run for members of the college to 
        fulfil many different potential interests. The societies are sometimes run solely 
        by WCR members, or jointly with JCR. If you have an interest in joining 
        any of these societies, you can get in touch with the listed contact for the 
        society of interest and find out how you can get involved. You can also join other 
        university societies as well.</p>
        <ButtonFlex display={[
          {key: 1, isLocal: true, des: "Create a college society!", link: "/societies-supports"},
          {key: 2, isLocal: false, des: "Room booking info", link: documents.pinkslip},
          {key: 3, isLocal: false, des: "Check room capacities", link: documents.roomCapacity}
        ]}/>
    </ContentBlock>

    <ContentBlock title="Societies A to Z">
      <p>Please browse below the list of college societies available for postgraduates. 
        The list is incomplete, and does not include undergraduate societies. See above for 
        registering your societies in our listings.</p>
      <section>
        <h3 style={{marginBottom: ".5rem"}}>Filter by the nature of societies:</h3>
        <FilterMenu 
          filter={natureFltr}
          updateFilter={updateNatureFilter}
        />
      </section>
      <section>
        <label htmlFor="societyaz-search"><h3 className="u-nomargin">Search fo the society below:</h3></label>
        <input 
          type="text"
          id="societyaz-search"
          value={search}
          onChange={handleSearchChange}
        />
      </section>
      <AllSocieties 
        societiesArr={societies}
        natureFltr={natureFltr}
        search={search}
      />
    </ContentBlock>
    {/* </Fade> */}
    </>
  );
}

export default SocietyAZ;