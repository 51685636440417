import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import qs from "qs";

import "../../utilities.css"
import "./TermCard.css";

import TopBanner from "../modules/TopBanner.js";
import ContentBlock from "../modules/ContentBlock.js";
import { scrollToTop } from "../../utilities.js";

const TermCard = () => {
    const [card, setCard] = useState({});

    const getCard = async () => {
      const query = qs.stringify({
        populate: { currentTermCard: { fields: ['url'] } }
      })

      await axios.get(`/strapi/api/general-document?${query}`
      ).then((res) => {setCard(res.data.data)}
      ).catch(e => {console.log(e)});
    }

    useEffect(() => {getCard();}, []);

    let backgroundImagesrc = "";

    if (card.currentTermCard) {
        backgroundImagesrc = card.currentTermCard.url
    }

    return (
      <>
        <TopBanner 
            backgroundImage={backgroundImagesrc}
            title="Events" 
            content="Current WCR Term Card"
        />
        <ContentBlock title="WCR Term Card">
          <p>The term card may not display the most updated events information. 
            Please regularly check the <Link to="/events" onClick={scrollToTop}>list of events</Link>.</p>
          <div className="termcard-container">
            { backgroundImagesrc && <img 
              src={backgroundImagesrc}
              style={{width: "100%"}} 
              alt="Please refer to the events page for an accessible version of the list of events."
              />
            }
          </div>
        </ContentBlock>
      </>
    )
}

export default TermCard;