import React from "react";
import { Link } from "react-router-dom";

import "../../../utilities.css"
import "./Building.css";

import TopBanner from "../../modules/TopBanner.js";
import ContentBlock from "../../modules/ContentBlock.js";
import ButtonFlex from "../../modules/ButtonFlex.js";
import { scrollToTop } from "../../../utilities.js";

import TopBannerImage from "../../../img/topbanner/mitchell_building.jpg";

const Building = () => {
  return (
    <>
      <TopBanner backgroundImage={TopBannerImage} title="About" content="Building" />

      <ContentBlock title="Our Building: Mitchell Building">
        <p>
          Our newly-refurbished WCR room is situated on the first floor of the 
          Mitchell Building, right behind the Old library. It is a regular meeting 
          point for the graduate community here at Univ. 
          A <Link to="/events" onClick={scrollToTop}>whole suite of 
          events</Link> are held in the WCR, including drinks receptions, art 
          socials, discussion groups and our graduate BOPs.
        </p>
        <p>The building is named after <a href="https://en.wikipedia.org/wiki/Sir_Harold_Mitchell,_1st_Baronet">Sir Harold Mitchell (1919, History)</a>, 
          an Old Member of the College. Sir Mitchell was a prominent businessman and 
          politician who funded the construction of this building and many other 
          educational projects.
        </p>
        <p>The building was built by John Fryman of the Architects Design 
          Partnership from 1967, as an effort of the then Building and 
          Development Committee to create a new social space for the graduate members 
          and redevelop the College Works Department and central kitchen.</p>
      </ContentBlock>
      <ContentBlock>
        <ButtonFlex display={[
          {key: 1, isLocal: false, des: "Click here to learn more about the history of the WCR!", link: "https://www.univ.ox.ac.uk/news/a-history-of-the-mitchell-building/"}
        ]}/>
      </ContentBlock>
      <ContentBlock title="Facilities">
        <ul>
          <li>Common Room Bar, available for use during events,</li>
          <li>fully equipped kitchen with a coffee machine,</li>
          <li>comfortable lounge with plenty of seatings,</li>
          <li>pigeon holes (or “pidges”) that hosts all mail sent to you during your stay in on-site college accommodation,</li>
          <li>two gender-neutral bathrooms (one cubicle only, one cubicle and urinal),</li>
          <li>a printer,</li>
          <li>a television equipped with gaming consoles, and</li>
          <li>an advanced sound system.</li>
        </ul>
      </ContentBlock>
    </>
  );
}

export default Building;
