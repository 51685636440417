import React from "react";

import EventStatus from "./EventStatus.js";
import { EventDeepLinks } from "../../utilities.js";

import "../../utilities.css";
import "./EventShortDes.css";

/**
 * EventShortDes is a component in the What's On page showing summaries 
 * of individual events.
 *
 * Proptypes
 * @param {Object} attributes
 */

const EventShortDes = ({attributes, statusToColor}) => {

  // See utilities.js on how the EventDeepLinks function is defined.
  const {googleQueryUrl, outlookQueryUrl} = EventDeepLinks({eventObj: attributes});

  return (
    <section style={{
      paddingInline: ".5rem",
      paddingBottom: ".5rem",
    }}>
      <h4 className="u-nomargin">{attributes.title} </h4>
      <div style={{
        marginBottom: ".5rem",
      }}> 
        {attributes.natures && attributes.natures.map(
          (tag) => {
            return (
              <span key={tag.id} className="eventshortdes-nature" style={{
                backgroundColor: tag.bkgColor,
                color: tag.textColor,
              }}>
                {tag.name}
              </span>);
            }
        )}
      </div>
      <div>
        Add to calendar: <a href={googleQueryUrl}>Google</a>, <a href={outlookQueryUrl}>Outlook</a> (Microsoft 365)
      </div>
      <EventStatus 
        startDate={attributes.startDate}
        endDate={attributes.endDate}
        status={attributes.eventStatus}
        statusToColor={statusToColor}
      />

      <div style={{fontSize: "0.95rem"}}>
        <p className="u-nomargin">
          Time: {new Date(attributes.startDate).toLocaleDateString(
        undefined, {
          weekday: 'short', 
          year: 'numeric', 
          month: 'short', 
          day: 'numeric', 
          hour: '2-digit', 
          hour12: false,
          minute: '2-digit',
          })}
        </p>
      { attributes.secondVenue ? (
        <>
          <p className="u-nomargin">First Venue: {attributes.firstVenue}</p>
          <p className="u-nomargin">Second Venue: {attributes.secondVenue}</p>
        </>
      ) : (
        <p className="u-nomargin">Venue: {attributes.firstVenue}</p>
      )
      }
      </div>
    </section>
    );
  }

export default EventShortDes;