import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import qs from "qs";
// import { Fade } from "react-awesome-reveal";

import "../../utilities.css"
import "./SupportSocieties.css";

import TopBanner from "../modules/TopBanner.js";
import ContentBlock from "../modules/ContentBlock.js";
import ButtonFlex from "../modules/ButtonFlex.js";
import TopBannerImage from "../../img/topbanner/temp_twelve_merton_street.jpg";
import { scrollToTop } from "../../utilities.js";

const SupportSocieties = () => {
  const [documents, setDocuments] = useState({})

  const getDocuments = async () => {
    const query = qs.stringify({
      fields: ['pinkslip', 'roomCapacity']
    });

    await axios.get(`/strapi/api/general-document?${query}`).then(
      (response) => {setDocuments(response.data.data);}
    )
  };

  useEffect(() => {getDocuments();}, []);

  return (<>
    <TopBanner 
      backgroundImage={TopBannerImage}
      bgColorKey="black"
      title="Societies" 
      content="Supports for college societies" 
    />

    <ContentBlock title="Introduction">
      <p>Thank you for your interests in setting up a new society for Univ. Your 
        initative would make our community more vibrant and diverse!</p>
      <p>Societies are not required to formally register to the College or any 
        common rooms. However, the WCR is here to support you in various ways 
        together with the college.</p>
    </ContentBlock>
    <ContentBlock title="IMPORTANT: Room Booking Regulation">
      <p>Make sure to abide with the room booking regulations if you wish to 
        host event within the college.</p>

      <p><b>University clubs are not allowed to host events in the college unless 
        booked by a College member who is the President of the society. Event of 
        such could not be advertised to general public.</b></p>
      
      <h2>Step 1: Preliminary Contacts</h2>
      <p>Before submitting event registration (aka the "pink slip"), you should 
        first contact:</p>

      <ul>
        <li>the domestic bursary 
          at <a href="mailto:hospitality@univ.ox.ac.uk">hospitality@univ.ox.ac.uk</a> to 
        check availability of rooms and obtain a reference number,</li>
        <li>the <a href="https://www.univjcr.com/committee">JCR President</a> if you wish to book the JCR,</li>
        <li>the <Link to="/about/governance" onClick={scrollToTop}>WCR President</Link> if you wish to book the WCR, and/or</li>
        <li>your fellow housemates if you want to host your event in your accommodations.</li>
      </ul>
      <h2>Step 2: Completing the pink slip</h2>
      <p>  
        You should then complete the "pink slip" at 
        the <a href="https://intranet.univ.ox.ac.uk">college intranet</a>. You 
        should include details of external speakers in your application, and ensure 
        the event does not exceed the room capacities as specified below:</p>

      <ButtonFlex display={[
        {key: 1, isLocal: false, des: "Room booking deadlines", link: documents.pinkslip},
        {key: 2, isLocal: false, des: "Check room capacities", link: documents.roomCapacity}
      ]}/>

      <p>The college reserves right to terminate any events that do not comply with 
        the above regulations. Organising or participating events without a pink 
        slip may lead to disciplinary actions.
      </p>
    </ContentBlock>

    <ContentBlock title="Promotion on the WCR Website">
      <h2>Landing Page</h2>

      <p>Any societies that weclome WCR members are invited to create a landing 
        page in this WCR website for promotion. To set up a new page, please contact 
        the <Link to="/about/governance" onClick={scrollToTop}>external affairs 
        officer</Link>, and include the following details:
      </p>
      <ul>
        <li>name of your society,</li>
        <li>a high-resolution logo/picture representing your society, ideally 
          of the dimension 16:9,</li>
        <li>contact person, which includes
          <ul>
            <li>President,</li>
            <li>a WCR contact, who should be a WCR member, and</li>
            <li>(optional) a full list of committee members with name and positions,</li>
          </ul>
        </li>
        <li>description of your society,</li>
        <li>schedule of regular meetings,</li>
        <li>nature of society: academic, music, sports, social, and</li>
        <li>links of promotion: website, Facebook, Instagram, Twitter. 
          (WhatsApp channels are not recommended due to spamming issues.)</li>
      </ul>

      <p>The JCR has an <a href="https://www.univjcr.com/student-societies">independent listing of the College societies</a>. 
         You can enquire by contacting the <a href="https://www.univjcr.com/committee">JCR IT Officer</a> for more information.</p>

      <p>Finally, the College also has an <a href="https://www.univ.ox.ac.uk/live-at-univ/sport-and-societies/">independent listing of the College societies</a>. 
         You can enquire by contacting the College Communication Office at <a href="mailto:communications@univ.ox.ac.uk">communications@univ.ox.ac.uk</a>.</p>

      <p><b>The WCR is looking into syncing the above process. For this, we will 
        provide regular updates to the societies who have registered for a 
        WCR landing page.</b></p>

      <h2>College Freshers' Fair</h2>
      <p>The College Freshers' Fair is run by the JCR. Please contact 
        the <a href="https://www.univjcr.com/committee">JCR Committee</a> if you wish 
        to reserve a stall.</p>

      <h2>Events</h2>
      <p>Please <Link to="/contact" onClick={scrollToTop}>contact us</Link> if you wish 
        to post your events on our <Link to="/events" onClick={scrollToTop}>events page</Link>, specifying:</p>
        <ul>
        <li>name of your group/society,</li>
        <li>contact person for the event,</li>
        <li>description of the event,</li>
        <li>venue, starting and ending times,</li>
        <li>nature of events (see <Link to="/events" onClick={scrollToTop}>events page</Link>) for details,</li>
        <li>links for registration / attendance if the event is online.</li>
      </ul>
      
      <p>The College also runs an independent newsletter for events update. You can 
        email the Communication Office at <a href="mailto:communications@univ.ox.ac.uk">communications@univ.ox.ac.uk</a> for 
        promoting your events on the newsletter.</p>

      <h2>Social Media Requests</h2>
      <p>The <Link to="/contact" onClick={scrollToTop}>Contact Us</Link> page 
        contains a list of social media that are actively managed by the WCR. Please
        contact <Link to="/about/governance" onClick={scrollToTop}>any of the Committee 
        members</Link> if you wish to be featured on our social media.</p>

      <p>The JCR and College also run their own social media. You can contact 
        the <a href="https://www.univjcr.com/committee">JCR Committee</a> or 
        the <a href="mailto:communications@univ.ox.ac.uk">College Communication Office</a> if 
        you wish to be featured in any of those social media.</p>
    </ContentBlock>
    <ContentBlock title="WCR Funding">
      <p>Any societies that are led by WCR members, or having sufficient 
        attendance by WCR members could apply funding from WCR. You can apply by 
        emailing separately to 
        the <Link to="/about/governance" onClick={scrollToTop}>Treasurer</Link> with the 
        following details:
      </p>
      <ul>
        <li>name of your society,</li>
        <li>email of a contact person: the contact person should ideally be a WCR member 
          who actively engage in your society, but if you struggle to find one, you may 
          ask <Link to="/about/governance" onClick={scrollToTop}>any of the committee members</Link> (including 
          the Treasurer) to apply on your behalf,</li>
        <li>a brief description of your society,</li>
        <li>a brief description on how the money would be used, and</li>
        <li>declaration if your society has apply funding elsewhere (including JCR funding).</li>
      </ul>
      <p>
        Alternatively, you can <Link to="/about/general_meeting" onClick={scrollToTop}>submit a motion</Link> to be considered in 
        a general meeting. We typically approve these applications/motions in the Michaelmas (first) Term of the academic year. 
        Once approved, you can reimburse your expenses directly from 
        the <Link to="/about/governance" onClick={scrollToTop}>Treasurer</Link>.
      </p>
      <p>
        You are also encouraged to seek funding from other sources as well, 
        including the College, JCR and the Oxford SU.
      </p>
    </ContentBlock>
    {/* </Fade> */}
    </>
  );
}

export default SupportSocieties;