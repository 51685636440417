import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import Markdown from "react-markdown";
// import { Fade } from "react-awesome-reveal";

import TopBanner from "../../modules/TopBanner.js";
import EventBanner from "../../modules/EventBanner.js";
import ContentBlock from "../../modules/ContentBlock.js";
import AllSocieties from "../../modules/AllSocieties.js";

import "../../../utilities.css"
import "./Event.css";


const Event = () => {
  let { eventShortTitle } = useParams();

  const [event, setEvent] = useState({});
  const [statusToColor, setStatusToColor] = useState({});

  const getEvent = async () => {
    const query = qs.stringify({
      filters: { shortTitle: { $eq: eventShortTitle }},
      populate: {
        natures: true,
        eventCategory: { fields: ["name", "bkgColor", "textColor"] },
        banner: { fields: ["url", "alternativeText", "caption"] },
        organisingSocieties: { 
          populate: { 
            banner: true,
            societyNature: true 
          },
        },
      }
    })

    await axios.get(`/strapi/api/events?${query}`
      ).then(response => setEvent(response.data.data[0])
      ).catch((e) => console.log(e));
  };

  const getStatusColor = async () => {
    const query = qs.stringify({
      sort: "name:asc",
      fields: ['name', 'bkgColor']
    })
    
    await axios.get(`/strapi/api/event-statuses?${query}`
      ).then((response) => {
        const catsObj = response.data.data
        const colorObj = catsObj.reduce((acc, { name, bkgColor }, index) => {
        acc[name] = { bkgColor: bkgColor };
        return acc;
      }, {})
      setStatusToColor(colorObj);
    })
  }

  useEffect(() => {getEvent(); getStatusColor();}, []);

  return (
    event.title ? (
    <>
      <EventBanner eventObj={event} statusToColor={statusToColor}/>
      <ContentBlock title="Description">
        <section className="event-intro">
          <Markdown>{event.description}</Markdown>
        </section>
      </ContentBlock>
      <ContentBlock title="Contacts">
        {/* using the legacy otherContacts attribute to avoid
          significant change of data schema. */}
        <Markdown>{event.otherContacts}</Markdown>
      </ContentBlock>

      { // photo acknowledgement
        event.banner && (
        <>
          { event.banner.caption &&
            (<ContentBlock title="Photo Acknowledgement">
              <p>{event.banner.caption}</p>
            </ContentBlock>) 
          }
        </> )
      }
      
      { // organising societies
        event.organisingSocieties[0] && (
          <ContentBlock title="Organising Societies">
            <AllSocieties 
              societiesArr={event.organisingSocieties} 
              natureFltr={{}}
              search=""
            />
          </ContentBlock>
        )
      }

    </> ) : (
    <>
      <TopBanner title="Event" content="Event not found!" />
      <ContentBlock title="Your requested event could not be found.">
        <section>
        Please navigate to your desired page using the navigation bar.
        </section>
      </ContentBlock>
    </> )
  );
};
  
export default Event;
