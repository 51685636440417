import React, {useState, useEffect} from "react";
import axios from "axios";
import qs from "qs";
// import { Fade } from "react-awesome-reveal";

import ContentBlock from "../modules/ContentBlock.js";
import ButtonFlex from "../modules/ButtonFlex.js";
import TopBanner from "../modules/TopBanner.js";
import FilterMenu from "../modules/FilterMenu.js";
import AllEvents from "../modules/AllEvents.js";
import AllNews from "../modules/AllNews.js";

import TopBannerImage from "../../img/topbanner/temp_univ_goose.jpg";

import "../../utilities.css"
import "./Freshers.css";

const Freshers = () => {
  const [news, setNews] = useState([]);
  const [events, setEvents] = useState([]);
  const [documents, setDocuments] = useState({})

  const getNews = async () => {
    const query = qs.stringify({
      filters: { freshers: { $eq: true } },
      sort: ['updatedAt:desc'],
      pagination: {limit: 25},
      populate: {
        banner: { fields: ['url', 'alternativeText'] },
      },
    })
    const response = await axios.get(`/strapi/api/news?${query}`);
    setNews(response.data.data);
  };

  const getEvents = async () => {
    const query = qs.stringify({
      sort: ['startDate'],
      pagination: {limit: 200},
      populate: {
        banner: {
          fields: ['url', 'alternativeText']
        },
        natures: {
          fields: ['name', 'bkgColor', 'textColor']
        },
        eventCategory: {
          fields: ['name', 'lightBkgColor', 'textColor']
        }
      },
    })

    await axios.get(`/strapi/api/events?${query}`).then((response) => {
      const allEvent = response.data.data;

      // filter for all freshers event
      const freshersEvent = allEvent.filter(
        (item) => {
          return item.natures.some(
            (oneNature) => {return oneNature.name === "Freshers"}
          )
        }
      )

      setEvents(freshersEvent);
    })
  };

  const [natureFltr, setNatureFltr] = useState({});
  const [catFltr, setCatFltr] = useState({});
  const [statusToColor, setStatusToColor] = useState({});

  const getNatures = async () => {
    const query = qs.stringify({
      sort: "name:asc",
      fields: ['name', 'bkgColor', 'textColor']
    })
    
    await axios.get(`/strapi/api/tags?${query}`
      ).then((response) => {
        const naturesObj = response.data.data
        const defaultNatureFilters = naturesObj.reduce((acc, { name, bkgColor, textColor }, index) => {
          // technically Freshers is always true, could still be included here
          acc[name] = {
            active: false,
            bkgColor: bkgColor,
            textColor: textColor
          };
        return acc;
      }, {})
      setNatureFltr(defaultNatureFilters);
    })
  }

  const getEventCategories = async () => {
    const query = qs.stringify({
      sort: "name:asc",
      fields: ['name', 'bkgColor', 'textColor']
    })
    
    await axios.get(`/strapi/api/event-categories?${query}`
      ).then((response) => {
        const catsObj = response.data.data
        const defaultCategoryFilters = catsObj.reduce((acc, { name, bkgColor, textColor }, index) => {
          acc[name] = {
            active: false,
            bkgColor: bkgColor,
            textColor: textColor
          };
          return acc;
        }, {})
      setCatFltr(defaultCategoryFilters);
    })
  };

  const getStatusColor = async () => {
    const query = qs.stringify({
      sort: "name:asc",
      fields: ['name', 'bkgColor']
    })
    
    await axios.get(`/strapi/api/event-statuses?${query}`
      ).then((response) => {
        const catsObj = response.data.data
        const colorObj = catsObj.reduce((acc, { name, bkgColor }, index) => {
        acc[name] = { bkgColor: bkgColor };
        return acc;
      }, {})
      setStatusToColor(colorObj);
    })
  }

  const getDocuments = async () => {
    const query = qs.stringify({
      fields: ['preWelcomeWeek', 'welcomeWeek']
    });

    await axios.get(`/strapi/api/general-document?${query}`).then(
      (response) => {setDocuments(response.data.data);}
    )
  };

  useEffect(() => {getNews();}, []);
  useEffect(() => {getDocuments();}, []);
  useEffect(() => {getNatures().then(
    getEventCategories()
  ).then(
    getStatusColor()
  ).then(
    getEvents()
  )}, []);

  const updateNatureFilter = (f) => {
    let newNatureFltr = { ...natureFltr }
    newNatureFltr[f].active = !newNatureFltr[f].active
    setNatureFltr(newNatureFltr)
  }

  const updateCatFilter = (f) => {
    let newCatFltr = { ...catFltr }
    newCatFltr[f].active = !newCatFltr[f].active
    setCatFltr(newCatFltr)
  }

  return (
    <>
      <TopBanner
        backgroundImage={TopBannerImage}
        bgColorKey="black"
        title="Freshers" 
        content="Freshers' Information" />
      <ContentBlock title="Hey there!">
        <p>You should have received official welcomes from various College members, and we look forward to your arrival as much as they do! 
          Please do ensure that you have completed the registrations on the College's <a href="https://www.univ.ox.ac.uk/learn-at-univ/new-students">New Students</a> page.
          You can find on this page the WCR welcoming letters, as well as other freshers' week information.</p>
        
        <ButtonFlex display={[
          {key: 1, isLocal: false, des: "Official New Student Page", link: "https://www.univ.ox.ac.uk/learn-at-univ/new-students"},
        ]}/>

        {/* this is where the news are */}
        <AllNews newsArr={news}/>
        
      </ContentBlock>

      <ContentBlock title="Freshers' Events">
        <p>We have just dropped the Freshers' timetables for 2024, and they are now loaded on this page. 
          Click on the event thumbnail (image) to view further event details. 😊
          You can also add the events into your Google or Microsoft 365 (Outlook) calendars, provided you have logged into any of those accounts. 
          However, please do check this page regularly for any event updates!</p>
        <p>
          You can download a PDF version of the timetables below.
          Updates on the Freshers' timetables will be announced on this website and circulated via our usual channels: email, Facebook and/or WhatsApp.
        </p>
        <ButtonFlex display={[
          {key: "prewelcome", isLocal: false, des: "Pre-Welcome Week", link: documents.preWelcomeWeek},
          {key: "welcome", isLocal: false, des: "Welcome Week", link: documents.welcomeWeek},
        ]}/>
        <section className="u-gridPic-2 freshers-filters">
          <div>
            <h3>Filter by the nature of events:</h3>
            <FilterMenu 
              filter={natureFltr}
              updateFilter={updateNatureFilter}
            />
          </div>
          <div>
            <h3>Filter by organiser:</h3>
            <FilterMenu 
              filter={catFltr}
              updateFilter={updateCatFilter}
            />
          </div>
        </section>
        <AllEvents 
          eventsArr={events}
          natureFltr={natureFltr}
          catFltr={catFltr}
          statusToColor={statusToColor}
        />
      </ContentBlock>
    </>
  );
};
  
export default Freshers;
