import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import "../../utilities.css";
import "./BottomBanner.css";

import ContentBlock from "./ContentBlock.js";
import ButtonFlex from "./ButtonFlex.js";
import { scrollToTop } from "../../utilities.js";

import oxfordLogo from "../../img/logo/oxLogo.svg";
import univLogo from "../../img/logo/univ_badge.png";

const BottomBanner = () => {
  // fetch today's date
  const thisYear = new Date().getFullYear();

  // get social media links
  const [socials, setSocials] = useState({});

  const getSocials = async () => { 
    const resSocials = await axios.get(
      '/strapi/api/social-media').then().catch(e => {console.log(e)});
    
    setSocials(resSocials.data.data);
  };

  useEffect(() => {getSocials();}, []);

  return (
    <>
      <ContentBlock title="About WCR">
        <p>
          The WCR has a double meaning: it refers to the graduate body of students at University College and also 
          to the physical space in the College where graduate students can relax and socialise. It is an engaging 
          and diverse community of early-researchers consisting around 150 members across all year groups and subjects. 
          Whether you are here for a one/two-year Masters programme or a DPhil programme we do hope you choose to get 
          involved with college life!
        </p>
        <ButtonFlex />
        <p>
          Together with the College, the WCR actively opposes and deplores racist 
          and other discriminatory behaviour and language and has made a commitment 
          to be proactive in working to ensure that our principles of equality, 
          diversity, inclusion, and tolerance are reflected in our practice. 
          You can help make Univ a more welcoming and inclusive community 
          by <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=G96VzPWXk0-0uv5ouFLPkQV_eky6B4NEgDJW6zd8Nv5UN1lUOVZRRVpDMTUxU1A2QVg3TDVYNFNFOC4u">sharing your experience</a>. Reports of discriminatory behaviour personally 
          experienced or witnessed are especially welcome as are any thoughts and 
          suggestions for how to make Univ a more inclusive place.
        </p>
      </ContentBlock>
      <div className="u-block u-textCenter u-darkgrey BottomBanner"> 
        <div className="BottomBanner-unionlogo">
            <a href="https://www.ox.ac.uk"><img src={oxfordLogo} alt="University of Oxford"/></a>
            <a href="https://www.univ.ox.ac.uk"><img src={univLogo} alt="University College, Oxford"/></a>
        </div>
        <div className="BottomBanner-sociallogo">
          {socials.email && (<a href={`mailto:${socials.email}`}><i className="fa-regular fa-envelope fa-2x" title="email"></i></a>)}
          {socials.facebook && (<a href={socials.facebook}><i className="fa-brands fa-facebook fa-2x" title="facebook"></i></a>)}
          {socials.instagram && (<a href={socials.instagram}><i className="fa-brands fa-instagram fa-2x" title="instagram"></i></a>)}
          {socials.twitter && (<a href={socials.twitter}><i className="fa-brands fa-twitter fa-2x" title="twitter"></i></a>)}
        </div>
        <div className="BottomBanner-description"> 
            <p> © {thisYear} Weir Common Room, University College, Oxford </p>
            <p> The Weir Common Room is the Middle Common Room representing the postgraduate members of the <a href="https://univ.ox.ac.uk" className="author">University College, Oxford</a>. </p>
            <br></br>
            <p> <Link to="/privacyaccessibility" className="author" onClick={scrollToTop}>Privacy and Accessibility</Link></p>
            <p> A website by <a href="https://samuel-chlam.github.io/newsite/#/" className="author">Samuel 
            Lam</a>.</p>
        </div>
      </div>
    </>
  );
};

export default BottomBanner