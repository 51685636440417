import React from "react";

import { EventDeepLinks } from "../../utilities.js";
import EventStatus from "./EventStatus.js";

import "../../utilities.css";
import "./EventBasicInfo.css";

/**
 * EventBasicInfo is a component in EventBanner displaying 
 * basic information of a WCR event on a event page.
 *
 * Proptypes
 * @param {Object} eventObj for a single event object.
 * @param {Object} statusToColor for color of status bar.
 */

const EventBasicInfo = ( {eventObj, statusToColor} ) => {

  // See utilities.js on how the EventDeepLinks function is defined.
  const {googleQueryUrl, outlookQueryUrl} = EventDeepLinks({eventObj})

  return (
    <div className="eventbasicinfo-basic">
      <EventStatus 
        startDate={eventObj.startDate}
        endDate={eventObj.endDate}
        status={eventObj.eventStatus}
        statusToColor={statusToColor}
      />
      <p>
        A {eventObj.category} event, happening <br/>
        <b>{new Date(eventObj.startDate).toLocaleDateString(
                undefined, {
                  weekday: 'short', 
                  year: 'numeric', 
                  month: 'short', 
                  day: 'numeric', 
                  hour: '2-digit', 
                  hour12: false,
                  minute: '2-digit',
                })}
        </b>
      </p>
      <p>
        Venue <br/>
        { eventObj.secondVenue ? (
            <>
              <b>{eventObj.firstVenue}</b>, then <br/> <b>{eventObj.secondVenue}</b>
            </>
          ) : (
            <b>{eventObj.firstVenue}</b>
          )
        }
      </p>
      <p>
        Nature of event<br/>
        {eventObj.natures && eventObj.natures.map((tag) => {
          return (
            <span key={tag.id} className="eventbasicinfo-nature" 
                  style={{backgroundColor: tag.bkgColor,
                          color: tag.textColor,
                        }}
            >
              {tag.name}
            </span>);
          }
        )}
      </p>

      <p className="eventbasicinfo-eventdeeplinks">
        Add to your personal calendars (please regularly check this page for updated info).
        <ul>
          <li><a href={googleQueryUrl}>Google</a></li>
          <li><a href={outlookQueryUrl}>Outlook</a> (Microsoft 365)</li>
        </ul>
      </p>

      {eventObj.promotionLink && <a 
        style={{textDecoration: "none"}}
        href={eventObj.promotionLink}><button className="eventbasicinfo-register">Information / Register Here</button></a>}
                
      {eventObj.eventLink && <a 
        style={{textDecoration: "none"}}
        href={eventObj.eventLink}><button className="eventbasicinfo-eventlink">Join here!</button></a>}
    </div>
  );
};

export default EventBasicInfo;